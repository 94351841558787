<template app>
    <div id="app">
        <v-card color="black">
            <v-card-text>
                <v-layout wrap v-if="dashvalues" class="pt-3">

                    <v-flex md4 class="text-center">
                        <span class="dashHeader">
                            <span class="anzeige"><span class="ma-3">{{dashvalues.personen}}</span></span><br /><br />PERSONEN
                        </span>
                    </v-flex>
                    <v-flex md4 class="text-center">
                        <span class="dashHeader">
                            <span class="anzeige"><span class="ma-3">{{dashvalues.firmen}}</span></span><br /><br />FIRMEN
                        </span>
                    </v-flex>
                    <v-flex md4 class="text-center">
                        <span class="dashHeader">
                            <span class="anzeige"><span class="ma-3">{{dashvalues.aktionen}}</span></span><br /><br />AKTIONEN
                        </span>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <template>
            <v-layout wrap>
                <v-flex xs12
                        class="mb-3">
                    <v-sheet height="600">
                        <v-toolbar color="accent">
                            <v-btn @click="back" color="black" dark>
                                <v-icon dark
                                        left>
                                  mdi-arrow-left-thick
                                </v-icon>
                                Zurück
                            </v-btn>
                            <v-spacer></v-spacer>
                            <h1 class="crmHeader text-white">{{monat}}</h1>

                            <v-spacer></v-spacer>

                            <v-btn color="black" @click="next()" dark>
                                Weiter
                                <v-icon right
                                        dark>
                                  mdi-arrow-right-thick
                                </v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-calendar ref="calendar"
                                    :type="type"
                                    v-model="start"
                                    :end="end"
                                    color="accent">
                            <template v-slot:day="{ date }">
                                <template v-for="(event, index) in eventsMap[date]">
                                    <v-menu :key="index"
                                            v-model="event.open"
                                            full-width
                                            offset-x>
                                        <template v-slot:activator="{ on }">
                                            <div v-if="!event.time"
                                                 v-ripple
                                                 class="my-event"
                                                 v-on="on"
                                                 v-html="event.title"></div>
                                        </template>
                                        <v-card color="grey lighten-4"
                                                min-width="350px"
                                                flat>
                                            <v-toolbar color="accent"
                                                       dark>
                                                <v-toolbar-title v-html="event.title"></v-toolbar-title>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>
                                            <v-card-title primary-title>
                                                <span v-html="event.details"></span>
                                            </v-card-title>
                                            <v-card-actions>
                                                <v-btn color="accent">
                                                    zurück
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </template>
                        </v-calendar>
                    </v-sheet>
                </v-flex>
            </v-layout>
        </template>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                type: 'month',
                date: null,
                today: null,
                start: '2019-01-01',
                end: '2019-12-31',
                monat: null,
                typeOptions: [
                    { text: 'Day', value: 'day' },
                    { text: '4 Day', value: '4day' },
                    { text: 'Week', value: 'week' },
                    { text: 'Month', value: 'month' },
                ],
                events: [],
                dashvalues: [],
                expanded: false,
                headers: ['heute', 'der nächsten 7 Tagen', 'der nächsten 30 Tage'],

            }
        },
        computed: {
            eventsMap() {
                const map = {}
                this.events.forEach(e => (map[e.date] = map[e.date] || []).push(e))
                return map
            }
        },
        mounted() {
            this.getCalendarValues();
            this.getDasboardValues();
            this.getMonth();
        },
        created() {

        },
        methods: {
            open: function (event) {
                alert(event.title)
            },
            getMonth: function (add) {
                var monate = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
                var date = new Date(this.$refs.calendar.start)
                this.monat = monate[date.getMonth()];
            },
            addMonth: function (add) {
                var monate = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
                var date = new Date(this.$refs.calendar.value)
                var month = date.getMonth() == 11 ? 0 : date.getMonth() + 1
                this.monat = monate[month];
            },
            subMonth: function (add) {
                var monate = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
                var date = new Date(this.$refs.calendar.value);
                var month = date.getMonth() == 0 ? 11 : date.getMonth() - 1

                this.monat = monate[month];
            },
            next: function () {
                this.addMonth();
                this.$refs.calendar.next();
            },
            back: function () {
                this.subMonth();
                this.$refs.calendar.prev();

            },
            count: function (list) {
                return list.count();
            },
            expandAll: function () {

                if (this.expanded) {
                    this.show = [false, false, false];
                    this.expanded = false;
                }
                else {
                    this.show = [true, true, true];
                    this.expanded = true;
                }

            },
            routeTo: function (name) {
                this.$router.push(name);
            },

            formateDate: function (dateString) {
                var date = new Date(dateString);

                var day = date.getDate().toString();
                day = day.length == 1 ? "0" + day : day;

                var month = (date.getMonth() + 1).toString();
                month = month.length == 1 ? "0" + month : month;

                var year = date.getFullYear().toString();
                return day + '.' + month + '.' + year;

            },
            getCalendarValues: function () {
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                this.today = yyyy + '-' + mm + '-' + dd;
                this.start = yyyy + '-' + mm + '-' + dd;
                this.$http.get('/api/Stammdaten/CalendarValues/').then(response => {
                    this.events = response.data;
                }).catch((error) => console.log(error));

            },

            getDasboardValues: function () {

                this.$http.get('/api/Stammdaten/DashboardValues/').then(response => {
                    this.dashvalues = response.data;
                }).catch((error) => console.log(error));

            }
        }
    }
</script>
